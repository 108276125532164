@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    height: -webkit-fill-available;
  }
  body,
  #__next {
    min-height: 100vh;
    height: -webkit-fill-available;
    @apply flex flex-col justify-between;
  }
}
